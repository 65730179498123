.place-wrapper{
    position: relative;
}

.place-container{
    margin: auto;
    max-width: 1280px;
    padding: 0 80px 60px 80px;
}

.place-gradient{
    background: linear-gradient(180deg, #FFFFFF 0%, #F5F6F6 100%);
    position: absolute;
    bottom: 0;
    height: 733px;
    width: 100%;
    z-index: -1;
}

.place-text-container{
    max-width: 870px;
    margin-bottom: 60px;
    z-index: 10;
    position: relative;
}

.place-title {
    font-family: Poppins, sans-serif;
    font-size: 3.75em;
    font-weight: 700;
    margin: 0 0 25px 0;
}

.place-price {
    font-family: Poppins, sans-serif;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 0 17px;
}

.place-price-from {
    font-weight: 400;
    color: #6C6C6C;
    font-size: 13px;
}

.place-price-amount {
    font-weight: 500;
    font-size: 18px;
}

.place-description{
    font-family: Lora, serif;
    font-size: 1.3125em;
    font-weight: 400;
    line-height: 1.4em;
    margin: 10px 0 0 0;
}

.place-sub-description-container{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
}

.place-image-container{
    padding-right: 220px;
    max-height: 632px;
    width: 100%;
}

.place-image{
    max-height: 632px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.place-sub-description-text-container{
    background-color: var(--yellow);
    max-width: 536px;
    box-sizing: border-box;
    padding: 48px 83px 48px 53px;
    position: absolute;
    border-radius: 10px 500px 500px 10px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.place-sub-title{
    font-family: Poppins, sans-serif;
    font-size: 2.5em;
    font-weight: 600;
    margin: 0 0 20px 0;
    padding-right: 50px;
    line-height: 1.2em;
}

.place-sub-description{
    font-family: Lora, serif;
    font-size: 1.3125em;
    font-weight: 400;
    line-height: 1.4em;
    margin-bottom: 33px;
}

.place-sub-description-button{
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 500;
    background-color: white;
    text-decoration: none;
    color: black;
    padding: 8px 26px;
    border-radius: 5px;
}

.place-sub-description-button-mobile{
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 500;
    background-color: var(--yellow);
    text-decoration: none;
    color: black;
    padding: 8px 26px;
    border-radius: 5px;
    margin-top: 35px;
    display: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.place-features-container{
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 100%;
}

.place-feature-container{
    background: linear-gradient(114.65deg, #DCE7F0 2.31%, #CBDFEF 100%);
    border-radius: 10px;
    padding: 42px;
    flex-grow: 1;
    flex-basis: 0;
    list-style: none;
}

.place-feature-title{
    font-family: Poppins, sans-serif;
    font-size: 1.75em;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;
}

.place-features ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.place-features{
    font-family: Lora, serif;
    font-size: 1.3125em;
    font-weight: 400;
    line-height: 1.4em;
}

.mobile-yellow-header{
    display: none;
    background-color: var(--yellow);
    clip-path: polygon(0 0, 100% 0%, 100% 83%, 0% 100%);
    width: 100%;
    height: 538px;
    position: absolute;
    top:-50px;
}

@media screen and (max-width: 1250px){

    .place-title{
        font-size: 2.5em;
    }

    .place-description{
        font-size: 1.125em;
    }

    .place-container{
        padding: 0 40px 60px 40px;
    }

    .place-image{
        max-height: 532px;
    }

    .place-sub-description-text-container{
        max-width: 436px;
        padding: 30px 53px 30px 30px;
    }

    .place-sub-title{
        font-size: 1.875em;
    }

    .place-sub-description{
        font-size: 1.125em;
    }

    .place-feature-container{
        padding: 30px;
    }

    .place-features{
        font-size: 1.125em;
    }
}

@media screen and (max-width: 900px){

    .place-container{
        padding: 0 40px 60px 40px;
    }

    .place-image{
        max-height: 432px;
    }

    .place-sub-description-text-container{
        max-width: 376px;
        padding: 30px 30px 30px 30px;
    }

    .place-sub-title{
        font-size: 1.875em;
    }

    .place-sub-description{
        font-size: 1em;
    }
}

@media screen and (max-width: 768px){

    .place-container{
        padding: 0 0 60px 0;
    }

    .place-sub-description-container{
        flex-direction: column;
    }

    .place-sub-description-text-container{
        background-color: unset;
        position: static;
        border-radius: 0;
        transform: none;
        max-width: unset;
        padding: 0 17px;
    }

    .place-title{
        padding: 0 17px;
    }

    .place-description{
        padding: 0 17px;
    }

    .place-sub-description{
        margin-bottom: 0;
    }

    .place-image-container{
        width: auto;
        padding: 0 17px;
        margin-bottom: 60px;
    }

    .place-features-container{
        flex-direction: column;
        padding-left: 17px;
    }

    .place-feature-container{
        padding: 20px 42px 20px 20px;
    }

    .mobile-yellow-header{
        display: block;
    }

    .place-sub-description-button-mobile{
        display: inline-block;
    }

    .place-sub-description-button{
        display: none;
    }

    .place-price {
        font-family: Poppins, sans-serif;
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
    }

    .place-price-from {
        font-weight: 400;
        color: #6C6C6C;
        font-size: 8px;
    }

    .place-price-amount {
        font-weight: 500;
        font-size: 12px;
    }
}

@media screen and (max-width: 480px){

    .place-image{
        max-height: 192px;
    }

    .place-feature-title{
        font-size: 1.325em;
        margin-bottom: 12px;
    }

    .place-text-container{
        margin-bottom: 22px;
    }

    .mobile-yellow-header{
        height: 338px;
    }
}

