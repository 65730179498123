.media-carousel-section{
    position: relative;
    padding-top: 110px;
}

.impressions-section{
    margin-top: 170px;
    margin-bottom: 100px;
    position: relative;
}

.founders-section{
    margin-top: 100px;
}

#about-page-decoration-1{
    height: 93px;
    position: absolute;
    left: 103px;
    top: 965px;
    z-index: 10;
}

#about-page-decoration-2{
    width: 400px;
    position: absolute;
    right: 0;
    bottom: -25px;
    z-index: 2;
}

#about-page-decoration-3{
    position: absolute;
    right: 20px;
    top: -55px;
}

@media screen and (max-width: 1600px){
    .media-carousel-section{
        padding-top: 70px;
    }

    #about-page-decoration-1{
        height: 80px;
        top: 925px;
    }
}

@media screen and (max-width: 1150px){
    #about-page-decoration-1{
        height: 80px;
        top: 635px;
    }
}

@media screen and (max-width: 768px){
    #about-page-decoration-1{
        height: 50px;
        left: 60px;
        top: 495px;
    }
}

@media screen and (max-width: 480px){
    #about-page-decoration-1{
        top: 305px;
        left: -50px;
    }

    #about-page-decoration-2{
        display: none;
    }

    .founders-section{
        margin-top: 60px;
    }

    .impressions-section{
        margin-top: 145px;
        margin-bottom: 0;
    }
}