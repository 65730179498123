
.navigation-container{
    height: 110px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: white;
    transition: all 0.2s linear;
    box-shadow: 0 0 14px 0 rgba(0,0,0,0.1);
}

.navigation{
    display: inline-flex;
    align-self: center;
    height: 100%;
}

.navigation-list
{
    list-style: none;
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-left: 94px;
    padding: 0;
}

.navigation-list-item{
    font-family: Poppins, sans-serif;
    font-weight: 500;
    padding: 2px 11px;
    border-radius: 20px;
    text-decoration: none;
    color: #00062F;
}

.navigation-list-item-selected{
    background-color: var(--green);
}

.navigation-list-item a {
    text-decoration: none;
    color: #00062F;
}

.navigation-list-item:hover{
    cursor: pointer;
}

.logo{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.navigation-language-selector{
    display: inline-flex;
    align-self: center;
    margin-right: 100px;
}

.navigation-language-selector-mobile{
    display: none;
    position: absolute;
    right: 17px;
    top: 25px;
}

.hamburger-react{
    margin-left: 17px;
    align-self: center;
    display: none;
    z-index: 110;
    position: absolute !important;
}

.navigation-socials-container{
    display: none;
}

.navigation-socials-title{
    font-family: Poppins, sans-serif;
    font-size: 0.9375em;
    font-weight: 600;
    color: var(--darkPurple);
    opacity: 0.6;
    margin-bottom: 14px;
}

.navigation-socials-icons{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.hamburger-react div:nth-child(odd) div{
    width: 24px !important;

}

.hamburger-react div div{
    height: 3px !important;
}

.navigation-mobile-decoration{
    display: none;
    position: absolute;
    top: 176px;
    left: 70px;
    z-index: -1;
}

/*@media screen and (max-width: 1730px){*/
/*    .logo{*/
/*        left: 65%;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1337px){*/
/*    .logo{*/
/*        left: 65%;*/
/*    }*/

/*    .navigation-list*/
/*    {*/
/*        gap: 20px;*/
/*        margin-left: 50px;*/
/*    }*/
/*}*/

@media screen and (max-width: 1600px){

    .navigation-container{
        height: 70px;
    }

    .logo{
        left: 50%;
        z-index: 110;
        max-width: 105px;
    }

    .navigation{
        background-color: var(--green);
        position: absolute;
        left: -100vw;
        height: 100vh;
        z-index: 100;
        width: 100vw;
        align-self: unset;
        transition: all 0.2s;
        flex-direction: column;
        overflow: scroll;
    }

    .navigation-list{
        flex-direction: column;
        align-self: flex-start;
        margin-top: 170px;
        margin-left: 17px;
        padding: 0;
    }

    .navigation-list-item{
        font-weight: 700;
        font-size: 2.375em;
        border-radius: 0;
    }

    .navigation-list-item {
        display: inline-block;
    }

    .navigation-list-item::after {
        content: "";
        display: block;
        width: 0;
        height: 3px;
        background: #00062F;
        transition: width 0.2s;
        border-radius: 10px;
    }

    .navigation-list-item:hover::after {
        width: 100%;
    }

    .navigation-socials-container{
        display: flex;
        flex-direction: column;
        margin: auto 0 62px 17px;
    }

    .navigation-language-selector-mobile{
        display: block;
        position: absolute;
        right: 17px;
        top: 25px;
        z-index: 110;
    }

    .navigation-language-selector{
        display: none;
    }

    .hamburger-react{
        display: block;
    }

    .menu-open{
        left: 0;
    }

    .navigation-mobile-decoration{
        display: block;
    }

    .navigation-list-item-selected{
        background-color: unset;
    }
}

@media screen and (max-height: 730px) and (max-width: 1150px){

    .navigation-list{
        gap: 10px;
        margin-top: 162px;
    }

    .navigation-list-item{
        font-size: 1.875em;
    }

    .navigation-socials-container{
        margin: auto 0 31px 17px;
    }
}

@media screen and (max-height: 600px) and (max-width: 1150px){

    .navigation-list{
        margin-top: 100px;
    }

    .navigation-list-item{
        font-size: 1.5em;
    }
}

@media screen and (max-width: 768px){

    .mobile-detail-screen{
        background-color: var(--yellow);
        transition: all 0.2s linear;
        box-shadow: none !important;
    }
}