.card-slider-container{
    background-color: var(--lightGreen);
    padding: 50px 0 50px 108px;
    border-radius: 10px;
}

.card-slider-cards-container{
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.card-slider-cards-container{
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.left{
    border-radius: 0 10px 10px 0;
}

.right{
    border-radius: 10px 0 0 10px;
    margin-right: 0;
    margin-left: auto;
    padding: 50px 0 50px 50px;
}

.card-slider-text-container{
    margin-bottom: 50px;
}

.card-slider-title{
    font-size: 2.5em;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    margin: 10px 0 0 0;
    position: relative;
}

.card-slider-subtitle{
    font-size: 1.125em;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    color: black;
    opacity: 0.5;
    margin: 0;
}

.card-slider-description{
    font-size: 1.3125em;
    font-family: Lora, serif;
    font-weight: 400;
    margin-top: 10px;
    max-width: 635px;
    line-height: 1.4em;
}

.card-slider-description p{
    position: relative;
}

#card-slider-decoration {
    position: absolute;
    right: 100px;
    top: 0;
}

#card-slider-decoration-blogs {
    position: absolute;
    right: 0;
    top: 75px;
}

@media (min-width: 1301px){
    .card-slider-container.right{
        margin-left: 110px;
    }
    .card-slider-container.left{
        margin-right: 110px;
    }
}

@media screen and (max-width: 1300px){
    .card-slider-container.right{
        margin-left: 40px;
    }
    .card-slider-container.left{
        margin-right: 40px;
    }
}

@media screen and (max-width: 900px){
    .card-slider-container{
        padding: 50px 0 50px 50px;
    }
    .card-slider-container.right{
        margin-left: 0px;
    }
    .card-slider-container.left{
        margin-right: 0px;
    }
}

@media screen and (max-width: 670px) {

    .card-slider-title{
        line-height: 1.2em;
    }

    .card-slider-text-container{
        text-align: center;
    }

    .impressions-section .card-slider-container{
        background-color: var(--lightGreen) !important;
    }

    .card-slider-container{
        background-color: white;
        max-width: 1270px;
        padding: 50px 17px 17px 17px;
        border-radius: 0;
    }

    .card-slider-cards-container{
        white-space: normal;
    }

    #card-slider-decoration{
        right: -45px;
    }

    #card-slider-decoration-blogs {
        width: 150px;
        margin: -96px 0 0 0;
    }
}

@media screen and (max-width: 480px) {

    .card-slider-title{
        font-size: 1.875em;
    }

    .card-slider-subtitle{
        font-size: 0.875em;
    }

    .card-slider-description{
        font-size: 1em;
    }
}



