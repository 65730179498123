.card-container{
    display: inline-flex;
    flex-direction: column;
    max-width: 550px;
    height: 560px;
    white-space: normal;
    margin-right: 50px;
    vertical-align: top;
}

.card-image-container{
    position: relative;
}

.card-image{
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: 347px;
}

.card-title{
    font-family: Poppins, sans-serif;
    font-size: 1.75em;
    font-weight: 600;
    margin-bottom: 15px;
}

.card-description{
    width: 100%;
    height: 100%;
    font-family: Lora, serif;
    font-size: 1.125em;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    line-height: 1.4em;
    word-break: normal;
}

.card-button{
    display: inline-flex;
    flex-grow: 1;
    gap: 12px;
    align-items: center;
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 600;
    text-decoration: none;
    color: black;
    padding: 10px 10px 10px 0;
    transition: all 0.2s;
}

.card-button:hover{
    gap: 25px;
}

.card-price-container{
    position: absolute;
    background-color: white;
    border-radius: 60px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 9px;
    bottom: -10px;
    right: 15px;
}

.card-price-prefix{
    font-family: Poppins, sans-serif;
    font-size: 0.5em;
    font-weight: 500;
    opacity: 0.6;
}

.card-price{
    font-family: Poppins, sans-serif;
    font-size: 0.8125em;;
    font-weight: 400;
}

@media screen and (max-width: 670px) {
    .card-container{
        max-width: 100%;
        margin: 20px 0 0 0;
    }

    .card-button{
        flex-grow: unset;
    }
}

@media screen and (max-width: 480px) {

    .card-container{
        height: 350px;
    }

    .card-image{
        height: 188px;
    }

    .card-title{
        font-size: 1.3125em;
        margin: 10px 0 5px 0;
    }

    .card-description{
        /*1.0em cuts the text off*/
        font-size: 0.93em;
    }

    .card-button{
        font-size: 1em;
    }
}
