.media-carousel .slick-dots{
    bottom: 40px !important;
}

.media-carousel .slick-dots{
    bottom: 30px !important;
}

.media-carousel .slick-dots li {
    width: 15px;
    height: 15px;
}

.media-carousel .slick-dots li button{
    opacity: 1 !important;
    transition: all 0.2s;
    background-color: white;
    border-radius: 50px;
    width: 100%;
    height: 100%
}

.media-carousel .slick-dots li button::before{
    content: none;
}

.media-carousel .slick-dots li.slick-active{
    width: 25px !important;
    transition: all 0.2s ease-out;
}

.media-carousel .slick-dots li{
    transition: all 0.2s ease-out;
}

.media-carousel .slick-dots li.slick-active button{
    background-color: var(--orange);
}

.media-carousel-image-slide-container{
    display: block;
    height: 900px;
}

.media-carousel-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.media-carousel-video-slide-container{
    background-color: black;
    height: 900px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.media-carousel-video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}

.media-carousel-text-container{
    padding: 144px 17px;
    display: inline-flex;
    flex-direction: row;
    gap: 26px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.media-carousel-title{
    font-family: Poppins, sans-serif;
    font-size: 2.5em;
    font-weight: 600;
    margin-top: 0;
    max-width: 288px;
    width: 100%;
}

.media-carousel-description{
    font-family: Lora, serif;
    font-size: 1.3125em;
    font-weight: 400;
    line-height: 1.4em;
    max-width: 494px;
    width: 100%;
}

@media screen and (max-width: 1150px){
    .media-carousel-image-slide-container{
        height: 600px;
    }

    .media-carousel-video-slide-container{
        height: 600px;
    }
}

@media screen and (max-width: 768px){
    .media-carousel-image-slide-container{
        height: 450px;
    }

    .media-carousel-video-slide-container{
        height: 450px;
    }

    .media-carousel-title{
        font-size: 1.875em;
    }

    .media-carousel-description{
        font-size: 1em;
    }
}

@media screen and (max-width: 480px){
    .media-carousel-image-slide-container{
        height: 250px;
    }

    .media-carousel-video-slide-container{
        height: 250px;
    }

    .media-carousel-text-container{
        flex-direction: column;
        gap: 0;
        padding: 60px 17px;
    }
}