.contact-background{
    background-image: url("/src/resources/images/contact-map.png");
    background-size: cover;
    background-position: center;
    height: 900px;
    width: 100%;
}

.contact-wrapper{
    margin: 0 108px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.contact-container{
    box-sizing: border-box;
    border-radius: 10px;
    background-color: var(--darkPurple);
    max-width: 400px;
    min-height: 600px;
    padding: 60px;
    position: relative;
}

.contact-text-container address{
    font-style: normal;
    position: relative;
    z-index: 1;
}

.contact-title{
    font-family: Poppins, sans-serif;
    font-size: 2.5em;
    font-weight: 600;
    color: white;
    margin: 0;
}

.contact-address-title{
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 600;
    color: white;
    margin: 30px 0 0 0;
    opacity: 0.6;
}

.contact-address{
    font-family: Lora, serif;
    font-size: 1.3125em;
    font-weight: 400;
    color: white;
    line-height: 1.4em;
}

.contact-route{
    font-family: Lora, serif;
    font-size: 1.3125em;
    font-weight: 500;
    display: inline-block;
    text-decoration: underline;
    color: white;
    margin-top: 15px;
}

.contact-info-title{
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 600;
    color: white;
    margin: 45px 0 0 0;
    opacity: 0.6;
}

.contact-info{
    display: block;
    font-family: Lora, serif;
    font-size: 1.3125em;
    font-weight: 400;
    color: white;
    line-height: 1.4em;
    text-decoration: underline;
}

address:first-of-type .contact-info{
    margin-bottom: 10px;
}

.contact-decoration{
    position: absolute;
    bottom: 45px;
    right: 0;
}

.contact-marker-wrapper{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.contact-marker-container{
    position: relative;
    top: 50px;
    left: calc(20px + 10vw);
}

.contact-marker{
    display: block;
    margin: 0 auto;
    animation: marker 10s ease infinite;
}

.contact-marker-text{
    display: block;
    margin-top:-10px;
    animation: we-are-here 10s ease infinite;
}

@keyframes we-are-here {
    3% { transform: scale(1.2); }
    4%, 6% { transform: rotate(-20deg) scale(1.2); }
    5% { transform: rotate(20deg) scale(1.2); }
    7% { transform: rotate(0deg) scale(1.2); }
    10% { transform: scale(1); }
    100% { transform: scale(1); }
}

@keyframes marker {
    0% { transform: scale(1.0); }
    5%{ transform: scale(1.2); }
    10%{ transform: scale(1.0); }
    100%{ transform: scale(1.0); }
}

@media screen and (max-width: 1450px){
    .contact-marker-container{
        position: relative;
        top: 50px;
        left: 160px
    }
}

@media screen and (max-width: 1100px){

    .contact-background{
        background-image: url("/src/resources/images/contact-map-mobile.png");
    }

    .contact-wrapper{
        margin: 0 90px ;
        position: static;
        transform: none;
        overflow: auto;
    }

    .contact-container{
        min-height: 452px;
        padding: 40px;
        text-align: center;
        margin: 90px auto 0 auto;
        display: flex;
        justify-content: center;
    }

    .contact-text-container{
        margin-bottom: 40px;
    }

    .contact-marker-container{
        position: relative;
        top: 195px;
        left: -100px;
    }
}

@media screen and (max-width: 480px){

    .contact-wrapper{
        margin: 0 50px;
    }

    .contact-container{
        padding: 40px 30px;
    }

    .contact-title{
        font-size: 1.875em;
    }

    .contact-address-title{
        font-size: 0.9375em;
    }

    .contact-address{
        font-size: 1em;
    }

    .contact-info-title{
        font-size: 0.9375em;
    }

    .contact-info{
        font-size: 1em;
    }

    .contact-route{
        font-size: 1em;
    }
}