.app-promotion-wrapper{
    margin: 0 108px;
}

.app-promotion-container{
    background-color: var(--mintGreen);
    border-radius: 10px;
    max-width: 1224px;
    padding: 80px 80px 30px 80px;
    display: flex;
    flex-direction: row;
    margin: auto;
    position: relative;
}

.app-promotion-text-app-links-container{
    display: inline-block;
}

.app-promotion-app-links-container{
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.app-promotion-text-container{
    max-width: 540px;
    margin-right: 40px;
    margin-bottom: 43px;
}

.app-promotion-title{
    font-family: Poppins, sans-serif;
    font-size: 2.5em;
    font-weight: 600;
    margin: 0;
}

.app-promotion-description{
    font-family: Lora, serif;
    font-size: 1.3125em;
    font-weight: 400;
    margin-top: 20px;
    line-height: 1.4em;
}

.app-promotion-app-links-title{
    display: block;
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 600;
    opacity: 0.6;
    margin-bottom: 13px;
}

.app-promotion-image-container{
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin-right: 80px;
    position: relative;
}

.app-promotion-image{
    align-self: self-start;
    max-width: 300px;
    margin-top: -165px;
    z-index: 20;
}

.app-promotion-decoration-1{
    position: absolute;
    right: -125px;
    bottom: -20px;
    width: 375px;
    z-index: 10;
}

.app-promotion-decoration-2{
    display: none;
    max-width: 25px;
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.app-promotion-decoration-3{
    display: none;
    max-width: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    transform: rotateZ(180deg);
}

@media screen and (max-width: 1100px){

    .app-promotion-wrapper{
        margin: 0 17px;
    }

    .app-promotion-container{
        padding: 40px 40px 30px 40px;
    }

    .app-promotion-title{
        font-size: 1.875em;
    }

    .app-promotion-description{
        font-size: 1em;
    }

    .app-promotion-image{
        max-width: 250px;
    }
}

@media screen and (max-width: 680px){

    .app-promotion-container{
        flex-direction: column;
        background-color: white;
        padding: 0;
        gap: 120px;
    }

    .app-promotion-image-container{
        border-radius: 10px;
        background-color: var(--mintGreen);
        margin-right: 0;
        padding: 0 55px 40px 55px;
    }

    .app-promotion-text-container{
        margin-right: 0;
    }

    .app-promotion-image{
        max-width: 250px;
        width: 100%;
        height: auto;
        margin-top: -50px;
    }

    .app-promotion-decoration-1{
        display: none;
    }

    .app-promotion-decoration-2{
        display: block;
    }

    .app-promotion-decoration-3{
        display: block;
    }
}
