body{
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.loading-screen{
    background: var(--green);
    width: 100vw;
    height: 100vh;
}

.loader-container{
    position: absolute;
    background: var(--green);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader{
    display: flex;
    justify-content: center;
}

.loader-text{
    font-family: Poppins, sans-serif;
    font-size: 2.5em;
    font-weight: 600;
    color: white;
    display: block;
    margin-top: 30px;
}

@media screen and (max-width: 480px){
    .loader svg{
        width: 60px;
        height: 60px;
    }

    .loader-text{
        font-size: 1.875em;
    }
}
