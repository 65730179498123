.blogs-grid-container{
    background-color: rgb(228, 236, 223);
    border-radius: 10px 0 0 10px;
    margin-right: 0;
    margin-left: calc((100vw - 1280px) / 2);
    margin-bottom: 60px;
    padding: 50px 50px 50px 50px;
}

.blogs-overview-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    max-width: 1230px;
}

.blog-overview-text{
    position: relative;
    z-index: 10;
    max-width: 1280px;
    margin: auto;
    padding: 210px 80px 60px 80px;
}

.blogs-button-container{
    max-width: 1230px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.show-more-button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    grid-gap: 12px;
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 600;
    text-decoration: none;
    color: black;
    background: none;
    padding: 10px 0px 10px 0px;
    border: none;
    outline: none;
    cursor:pointer;
}

@media screen and (max-width: 1600px){
    .blog-overview-text{
        padding-top: 110px;
    }
}

@media screen and (max-width: 1250px) {
    .blog-overview-text{
        padding: 80px 40px 40px 40px;
    }

    .blogs-grid-container{
        margin: 0;
        padding: 40px 40px 40px 40px;
    }

    .blogs-overview-grid{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }
}

@media screen and (max-width: 900px){
}

@media screen and (max-width: 768px){
    .blog-overview-text{
        padding: 110px 17px 60px 17px;
    }

    .blogs-grid-container{
        margin: 0;
        padding: 17px 17px 17px 17px;
    }

    .blogs-overview-grid{
        grid-gap: 17px;
    }
}

@media screen and (max-width: 480px){
}