p{
    margin: 0;
}

.header-background {
    height: 887px;
    width: 100%;
    background-size: cover;
    overflow: hidden;
}

.header-box {
    width: 830px;
    margin-top: 60px;
    padding: 50px 0;
    border-top-right-radius: 500px;
    border-bottom-right-radius: 500px;
    background-color: var(--green);
    font-family: Poppins, sans-serif;
    overflow: hidden;
}

.header-text-container {
    margin-left: 110px;
}

.header-fixed-title {
    font-size: 3.75em;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    color: white;
    margin: 0;
}

.header-animated-title {
    font-size: 3.75em;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    color: #00062F;
    position: relative;
    bottom: 25px;
    overflow: hidden;
    white-space: nowrap;
}

.header-cursor {
    font-weight: 300;
}

.header-description{
    font-size: 1.3125em;
    font-family: Lora, serif;
    font-weight: 400;
    color: #00062F;
    margin-top: -10px;
    max-width: 500px;
    line-height: 1.4em;
}

.header-button {
    display: inline-block;
    background-color: white;
    color: black;
    border: none;
    font-size: 1.125em;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    border-radius: 5px;
    padding: 8px 27px;
    margin-top: 50px;
    text-decoration: none;
}

.header-mobile-image{
    display: none;
}

@media (min-width: 1900px){
    .header-box{
        width: 45%;
    }
    .header-description{
        max-width: none;
        width: 80%;
    }
}

@media screen and (max-width: 1366px) {
    .header-box{
        width: 50%;
        padding: 0;
    }
    .header-text-container{
        margin: 25px;
    }
    .header-fixed-title {
        font-size: 2.5em;
        margin-bottom: 10px;
    }
    .header-animated-title {
        font-size: 2.5em;
    }
    .header-description{
        font-size: 1.125em;
    }
    .header-button {
        font-size: 1em;
        margin-top: 25px;
    }
}

@media screen and (max-width: 887px) {
    .header-box {
        width: auto;
        max-width: 550px;
        margin-right: 20px;

    }

    .header-text-container{
        margin-left: 17px;
    }

    .header-fixed-title {
        font-size: 2.5em;
        margin-bottom: 10px;
    }

    .header-animated-title {
        font-size: 2.5em;
    }

    .header-button {
        font-size: 1em;
    }
}

@media screen and (max-width: 680px) {
    .header-background {
        background-image: none;
        height: auto;
        overflow: visible;
        position: relative;
    }

    .header-fixed-title {
        font-size: 2.5em;
    }

    .header-animated-title {
        font-size: 2.5em;
    }

    .header-box {
        margin: 0;
        padding: 25px 17px 170px 17px;
        border-radius: 0;
        min-height: 367px;
        box-sizing: border-box;
        max-width: none;
    }

    .header-text-container{
        margin-left: 0;
    }

    .header-description{
        font-size: 1em;
    }

    .header-button {
        position: absolute;
        bottom: 20px;
        right: 24px;
    }

    .header-mobile-image{
        display: block;
        background-size: cover;
        min-height: 280px;
        width: 100%;
        margin-top: -150px;
    }
}

@media screen and (max-width: 480px) {
    .header-fixed-title {
        font-size: 1.875em
    }

    .header-animated-title {
        font-size: 1.875em
    }

    .header-mobile-image {
        min-height: 210px;
        margin-top: -110px;
    }

    .header-box {
        padding: 25px 17px 130px 17px;
    }
}

