.footer-background{
    background-color: black;
    min-height: 422px;
    padding: 60px 110px;
    box-sizing: border-box;
}

.footer-grid{
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 105px;
    grid-row-gap: 68px;
    max-width: 1000px;
    vertical-align: top;
}

.footer-grid-item{
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.footer-grid-item-title{
    font-family: Poppins, sans-serif;
    font-size: 1em;
    font-weight: 600;
    color: white;
    opacity: 0.6;
}

.footer-logo-container{
    height: 100%;
    margin: 0 157px 55px 0px;
    display: inline-block;
}

.footer-socials-container{
    display: flex;
    flex-direction: row;
    gap: 19px;
}

.footer-app-links-container{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 15px;
}

.footer-address-container{
    max-width: 210px;
}

.footer-phone-number{
    margin-top: 10px;
}

.footer-address-text{
    font-family: Lora, serif;
    font-size: 1.125em;
    font-weight: 400;
    font-style: normal;
    color: white;
    display: block;
    line-height: 1.4em;
}

@media screen and (max-width: 800px){

    .footer-background{
        padding: 30px 0 50px 17px;
        box-sizing: border-box;
    }

    .footer-grid{
        grid-template-columns: 1fr;
        grid-row-gap: 50px;
    }

    .footer-logo-container{
        display: block;
    }
}

@media screen and (max-width: 480px){

    .footer-address-text{
        font-size: 1em;
    }
}