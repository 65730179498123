
.video-and-text-wrapper{
    margin: 0 108px;
}

.video-and-text-container{
    background-color: var(--yellow);
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    max-width: 1224px;
    box-sizing: border-box;
    padding: 70px 50px 70px 50px;
    border-radius: 10px;
    position: relative;
}

.video-and-text-video{
    max-width: 550px;
    min-height: 400px;
    object-fit: cover;
    margin-top: -100px;
    pointer-events: none;
}

.video-and-text-image{
    max-width: 550px;
    min-height: 400px;
    object-fit: cover;
    margin-top: -100px;
}

.video-and-text-text-container{
    max-width: 100%;
    margin-left: 40px;
}

.video-and-text-text-container p{
    font-size: 1.3125em;
    font-family: Lora, serif;
    font-weight: 400;
    margin-top: 20px;
    line-height: 1.4em;
}

.video-and-text-text-container h2{
    font-size: 2.5em;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    margin: 0;
    line-height: 1.125em;
}

.video-and-text-button{
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 600;
    padding: 10px 10px 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: black;
    gap: 12px;
    margin-top: 30px;
    transition: all 0.2s;
}

.video-and-text-button:hover{
    gap: 25px;
}

.video-and-text-button{
    text-decoration: none;
}

@media screen and (max-width: 1300px){

    .video-and-text-wrapper{
        margin: 0 40px;
    }

    .video-and-text-container{
        padding-bottom: 50px;
    }

    .video-and-text-image{
        max-width: 450px;
    }

    .video-and-text-text-container p{
        font-size: 1.125em;
    }

    .video-and-text-text-container h2{
        font-size: 1.875em;
    }

    .video-and-text-button{
        font-size: 1em;
    }

    .video-and-text-video{
        width: 450px;
    }
}

@media screen and (max-width: 900px){

    .video-and-text-wrapper{
        margin: 0;
    }

    .video-and-text-container{
        flex-direction: column-reverse;
        padding: 63px 0 0 0;
    }

    .video-and-text-text-container{
        max-width: 600px;
        margin-right: 40px;
    }

    .video-and-text-image{
        max-width: none;
        min-height: auto;
        max-height: 350px;
        margin-top: 40px;
    }

    .video-and-text-video{
        max-width: none;
        min-height: auto;
        max-height: 350px;
        margin-top: 40px;
    }

    .video-and-text-video{
        width: 100%;
        margin-top: 30px;
    }
}

@media screen and (max-width: 480px){

    .video-and-text-text-container p{
        font-size: 1em;
    }

    .video-and-text-text-container{
        margin: 0 17px;
    }

    .video-and-text-button{
        margin-top: 15px;
    }
}