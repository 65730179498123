.blogs-section{
    margin-top: 100px;
    margin-bottom: 100px;
    position: relative;
}

.blog-detail-section{
    padding-top: 210px;
    position: relative;
}

.blog-container{
    margin: auto;
    max-width: 1280px;
    padding: 0 80px 60px 80px;
}

.blog-text-container{

    max-width: 870px;
    z-index: 10;
    position: relative;
}

.blog-title{
    font-family: Poppins, sans-serif;
    font-size: 3.75em;
    font-weight: 700;
    margin: 0 0 25px 0;
}

.blog-date-container{
    display: flex;
    align-items: center;
    gap: 9px;
    margin: 0 0 25px 0;
}

.blog-date-year{
    font-family: Poppins, sans-serif;
    font-size: 0.5em;
    font-weight: 500;
    opacity: 0.6;
}

.blog-date-monthday{
    font-family: Poppins, sans-serif;
    font-size: 0.8125em;;
    font-weight: 400;
}

.blog-intro{
    font-family: Lora, serif;
    font-size: 1.3125em;
    font-weight: 400;
    line-height: 1.4em;
}

.media-carousel-section{
    position: relative;
    padding-top: 50px;
}

.blog-back-button{
    display: inline-flex;
    flex-grow: 1;
    gap: 12px;
    align-items: center;
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 600;
    text-decoration: none;
    color: black;
    padding: 10px 10px 10px 0;
    transition: all 0.2s;
}

.blog-back-button:hover{
    gap: 25px;
}

@media screen and (max-width: 1600px){
    .blog-detail-section{
        padding-top: 110px;
    }

    .media-carousel-section{
        padding-top: 50px;
    }
}

@media screen and (max-width: 1250px) {
    .blog-container {
        padding: 0 40px 60px 40px;
    }
    .blog-title{
        font-size: 2.5em;
    }
    .blog-intro{
        font-size: 1.125em;
    }
}

@media screen and (max-width: 900px){
    .blog-container{
        padding: 0 40px 60px 40px;
    }
}

@media screen and (max-width: 768px){
    .blog-container{
        padding: 0;
    }

    .blog-text-container{
        padding: 0 17px;
    }
}

@media screen and (max-width: 480px){
    .blogs-section{
        margin: 0;
    }
}