.office-and-space-detail-section{
    padding-top: 210px;
    position: relative;
}

.renters-section{
    padding-top: 100px;
    position: relative;
}

.other-options-section{
    margin-top: 100px;
    margin-bottom: 143px;
    position: relative;
}

#decoration-1{
    display: none;
    position: absolute;
    height: 70px;
    right: -10px;
    top: 705px;
}

#decoration-2{
    position: absolute;
    height: 121px;
    top: -20px;
    right: -40px;
}

#decoration-3{
    position: absolute;
    top: 35px;
    height: 70px;
    z-index: -1;
}

@media screen and (max-width: 1600px){
    .office-and-space-detail-section{
        padding-top: 110px;
    }
}

@media screen and (max-width: 768px){

    #decoration-1{
        display: block;
    }

    #decoration-2{
        height: 70px;
        right: -20px;
    }
}

@media screen and (max-width: 670px){
    #decoration-3{
        top: 250px;
        height: 53px;
        z-index: 0;
        right: 60px
    }
}

@media screen and (max-width: 480px){
    .other-options-section{
        margin-bottom: 40px;
    }

    #decoration-1{
        position: absolute;
        height: 53px;
        right: -10px;
        top: 480px;
    }

    #decoration-3{
        top: 220px;
    }
}