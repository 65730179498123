.founders-container{
    max-width: 1100px;
    display: flex;
    flex-direction: row;
    gap: 80px;
    margin: 0 auto;
    padding: 0 50px;
}

.founders-images-container{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-grow: 1;
    min-width: 300px;
}

.founders-image{
    height: auto;
    max-width: 190px;
    width: 100%;
}

#founder-image-1{
    z-index: 1;
    margin-bottom: -135px
}

#founder-image-2{
    position: relative;
    margin-left: 140px;
    z-index: 2;
}

#founder-image-3{
    z-index: 3;
    margin-top: -105px
}

.founders-text-container{
    max-width: 600px;
}

.founders-quote{
    font-family: Poppins, sans-serif;
    font-size: 1.75em;
    font-weight: 600;
    line-height: 1.6em;
    margin-bottom: 30px;
}

.founders-names{
    font-family: Poppins, sans-serif;
    font-size: 1.75em;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
}

.founders-position{
    font-family: Poppins, sans-serif;
    font-size: 1.3125em;
    font-weight: 300;
    display: block;
    padding-left: 25px;
}

@media screen and (max-width: 900px){

    .founders-container{
        flex-direction: column-reverse;
        gap: 60px;
    }

    .founders-image-container{
        margin: 0 0 0 -25px;
    }

    .founders-image-container:first-of-type{
        margin: 0 !important;
    }

    .founders-images-container{
        flex-direction: row;
        display: flex;
        grid-gap: unset;
        min-width: unset;
    }

    .founders-image{
        height: auto;
        max-width: 190px;
        width: 100%;
    }

    #founder-image-1{
        margin: 0;
    }

    #founder-image-2{
        margin: 0;
        position: unset;
        padding: 0;
    }

    #founder-image-3{
        margin: 0;
    }
}

@media screen and (max-width: 768px){
    .founders-quote{
        font-size: 1.3125em;
    }

    .founders-names{
        font-family: Poppins, sans-serif;
        font-size: 1em;
        font-weight: 400;
        display: block;
        margin-bottom: 5px;
    }

    .founders-position{
        font-family: Poppins, sans-serif;
        font-size: 0.875em;
    }
}

@media screen and (max-width: 480px){
    .founders-container{
        padding: 0 17px;
    }
}
