
.language-selector-item{
    font-family: Poppins, sans-serif;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 20px;
}

.language-selector-item:hover{
    cursor: pointer;
}

.selected-language{
    background-color: var(--green);
}