.carousel-slide-wrapper {
    max-width: 1250px;
    margin: auto;
    display: block !important;
}

.carousel-slide-container{
    display: flex !important;
    align-items: center;
    justify-content: center;
    max-width: 1250px;
    width: auto !important;
    margin: auto;
    padding: 0 80px;
}

.carousel-title {
    max-width: 1250px;
    padding-left: 93px;
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 400;
    color: black;
    opacity: 0.5;
    margin: 0 auto 20px auto;
}

.carousel-slide-title{
    font-family: Poppins, sans-serif;
    font-size: 2.5em;
    font-weight: 600;
    margin: 0;
    padding-left: 93px;
}

.carousel-slide-left{
    background-color: var(--mintGreen);
    max-width: 510px;
    min-height: 392px;
    width: 100%;
    box-sizing: border-box;
    padding: 42px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: -180px;
    z-index: 10;
    border-radius: 10px;
}

.carousel-slide-left-image{
    object-fit: cover;
    max-width: 80px;
    height: 90px;
    border-radius: 0 40px 40px 0;
}

.carousel-slide-left-review{
    padding-bottom: 20px;
    line-height: 1.4em;
}

.carousel-slide-left-renter-container{
    display: flex;
    flex-direction: row;
    gap: 17px;
}

.carousel-slide-left-renter-name{
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 600;
    margin: 0;
}

.carousel-slide-left-renter-function{
    font-family: Poppins, sans-serif;
    font-size: 0.9375em;
    font-weight: 300;
}

.carousel-slide-left-socials-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 14px;
}

.carousel-slide-left-socials-container img{
    width: 24px;
    height: 24px;
}

.carousel-slide-left-text-container h1,
.carousel-slide-left-text-container h2,
.carousel-slide-left-text-container h3,
.carousel-slide-left-text-container h4,
.carousel-slide-left-text-container h5,
.carousel-slide-left-text-container h6
{
    margin: 0 0 15px 0;
    font-family: Poppins, sans-serif;
    font-size: 1.75em;
    font-weight: 600;
    line-height: 1.3em;
}

.carousel-slide-left-text-container p{
    font-family: Lora, serif;
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.4em;
}

.carousel-slide-left-text-container{
    flex-grow: 1;
}

.carousel-slide-right{
    max-width: 730px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.carousel-slide-right-image{
    object-fit: cover;
    width: 100%;
    height: 500px;
    border-radius: 10px;
}

.carousel-next-button{
    position: absolute;
    right: 15%;
    top: 55%;
    transform: translateY(-50%);
    z-index: 10;
    transition: all 0.25s;
}

.carousel-next-button:hover{
    cursor: pointer;
    transform: scale(1.23) translateY(-50%);
}

.carousel-prev-button{
    position: absolute;
    left: 15%;
    top: 55%;
    transform: translateY(-50%);
    z-index: 10;
    transition: all 0.25s;
}

.carousel-prev-button:hover{
    cursor: pointer;
    transform: scale(1.23) translateY(-50%);
}

@media screen and (max-width: 1750px){

    .carousel-next-button{
        right: 8%;
    }

    .carousel-prev-button{
        left: 8%;
    }
}

@media screen and (max-width: 1450px){

    .carousel-next-button{
        right: 2%;
    }

    .carousel-prev-button{
        left: 2%;
    }
}

@media screen and (max-width: 1150px){

    .carousel-slide-left-text-container h1,
    .carousel-slide-left-text-container h2,
    .carousel-slide-left-text-container h3,
    .carousel-slide-left-text-container h4,
    .carousel-slide-left-text-container h5,
    .carousel-slide-left-text-container h6
    {
        font-size: 1.3125em;
    }

    .carousel-slide-right{
        min-width: 600px;
    }

    .carousel-slide-left-renter-function{
        font-size: 0.875em;
    }
}

@media screen and (max-width: 900px){
    .carousel-next-button{
        right: 40%;
        top: unset;
        bottom: -20%;
    }

    .carousel-prev-button{
        left: 40%;
        top: unset;
        bottom: -20%;
    }

    .carousel-slide-right{
        min-width: 500px;
    }
}

@media screen and (max-width: 768px){
    .slick-slide {
        display: flex;
        height: auto;
    }

    .slick-slide > div {
        padding: 0 5px;
    }

    .carousel-slide-left-review {
        min-height: 350px;
        max-height: 350px;
    }

    .carousel-slide-left-review > p {
        -webkit-line-clamp: 12;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: 1.4em;
        word-break: normal;
    }

    .carousel-title{
        margin-bottom: 10px;
        padding-left: 30px;
    }

    .carousel-slide-title{
        font-size: 1.875em;
        margin-bottom: 15px;
        padding-left: 0px;
    }

    .carousel-next-button{
        right: 50%;
        bottom: -40px;
        margin-right: -60px;
        transition: none;
    }

    .carousel-next-button:hover{
        transform: translateY(-50%);
    }

    .carousel-prev-button{
        left: 50%;
        bottom: -40px;
        margin-left: -60px;
        transition: none;
    }

    .carousel-prev-button:hover{
        transform: translateY(-50%);
    }

    .carousel-slide-container {
        flex-direction: column-reverse;
        padding: 0;
    }

    .carousel-slide-right{
        display: flex;
        border-radius: 10px 10px 0 0;
        max-width: unset;
        min-width: unset;
    }

    .carousel-slide-right-image{
        border-radius: 0;
        height: 300px;
    }

    .carousel-slide-left{
        max-width: unset;
        padding: 25px 0 25px 0;
        margin-right: 0;
        border-radius: 0 0 10px 10px;
    }

    .carousel-slide-left-text-container{
        padding: 0 17px;
    }

    .carousel-slide-left-renter-container{
        padding-left: 20px;
    }
}

@media screen and (max-width: 480px){
    .carousel-slide-right-image{
        height: 200px;
    }
}