:root{
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.homeSection{
    padding-top: 110px;
}

.aboutSection{
    margin-top: 100px;
    position: relative;
}

.officesSection{
    margin-top: 100px;
    position: relative;
}

.spacesSection{
    position: relative;
    margin-top: 100px;
}

.appSection{
    margin-top: 160px;
}

.blogsSection{
    position: relative;
    margin-top: 100px;
}

.contactSection{
    margin-top: 160px;
    position: relative;
}

#home-page-decoration-1{
    height: 92px;
    position: absolute;
    top: 325px;
    z-index: -1;
}

#home-page-decoration-2{
    position: absolute;
    top: -45px;
    right: 30px;
}

#home-page-decoration-3{
    display: none;
    position: absolute;
    top: 280px;
    right: 50px;
}

#home-page-decoration-5{
    position: absolute;
    top: -50px;
}


@media screen and (max-width: 1600px){
    .homeSection{
        padding-top: 70px;
    }
}

@media screen and (max-width: 900px){
    #home-page-decoration-1{
        top: -50px;
        z-index: 1;
    }
}

@media screen and (max-width: 670px){

    .contactSection{
        margin-top: 50px;
    }

    #home-page-decoration-2{
        display: none;
    }

    #home-page-decoration-3{
        display: block;
    }

    #home-page-decoration-5{
        display: none;
    }
}

@media screen and (max-width: 680px){
    .appSection{
        margin-top: 60px;
    }
}


@media screen and (max-width: 480px){
    .officesSection{
        margin-top: 0;
    }

    .spacesSection{
        margin-top: 0;
    }

    #home-page-decoration-3{
        top: 250px;
    }
}