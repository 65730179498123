.renters-carousel .slick-slide{
    display: flex;
    justify-content: center;
    height: auto;
}

.renters-carousel .slick-track {
    display: flex !important;
    gap: 24px !important;
}

.renters-carousel .slick-track::before{
    content: none;
}

.renters-carousel .slick-dots{
    bottom: 60px !important;
}

.renters-carousel .slick-dots li {
    width: 15px;
    height: 15px;
}

.renters-carousel .slick-dots li button{
    opacity: 0.3 !important;
    transition: all 0.2s;
    background-color: var(--orange);
    border-radius: 50px;
    width: 100%;
    height: 100%
}

.renters-carousel .slick-dots li button::before{
    content: none;
}

.renters-carousel .slick-dots li.slick-active{
    width: 25px !important;
    transition: all 0.2s ease-out;
}

.renters-carousel .slick-dots li{
    transition: all 0.2s ease-out;
}

.renters-carousel .slick-dots li.slick-active button{
    opacity: 0.2 !important;
}

.renters-carousel-container{
    background: linear-gradient(180deg, #F5F6F6 0%, #FFFFFF 100%);
}

.renters-carousel-text{
    max-width: 1250px;
    margin: 0 auto 60px auto;
    padding: 60px 17px 0 17px;
    box-sizing: border-box;
    position: relative;
}

.renters-carousel-title{
    font-family: Poppins, sans-serif;
    font-size: 3.75em;
    font-weight: 700;
    margin: 0 0 25px 0;
}

.renters-carousel-description{
    font-family: Lora, serif;
    font-size: 1.3125em;
    font-weight: 400;
    line-height: 1.4em;
}

.renters-carousel{
    max-width: 1250px;
    margin: auto;
    padding: 0 30px 94px 50px;
    display: flex !important;
    justify-content: center;
}

.renters-carousel-card{
    display: flex !important;
    flex-direction: column;
    background-color: var(--mintGreen);
    border-radius: 20px;
    padding: 43px 34px;
    max-width: 392px;
    box-sizing: border-box;
    height: 100%;
    z-index: 1;
}

.renters-carousel .slick-slide:nth-child(3n+2) .renters-carousel-card{
    background-color: var(--lightBlue);
}

.renters-carousel .slick-slide:nth-child(3n+0) .renters-carousel-card{
    background-color: var(--yellow);
}

.renter-carousel-card-header{
    display: flex;
    flex-direction: row;
    gap: 19px;
    margin-bottom: 27px;
}

.renter-carousel-card-header-text-container{
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 5px;
}

.renter-carousel-card-renter-name{
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 600;
    margin: 0;
}

.renter-carousel-card-renter-position{
    font-family: Poppins, sans-serif;
    font-size: 0.9375em;
    font-weight: 300;
    margin: 0;
}

.renter-carousel-card-header-image{
    width: 61px;
    height: 68px;
    border-radius: 0 100px 100px 0;
    object-fit: cover;
}

.renters-carousel-card-text h1,
.renters-carousel-card-text h2,
.renters-carousel-card-text h3,
.renters-carousel-card-text h4,
.renters-carousel-card-text h5,
.renters-carousel-card-text h6
{
    font-family: Poppins, sans-serif;
    font-size: 1.125em;
    font-weight: 600;
    margin: 0 0 8px 0;
}

.renters-carousel-card-text{
    font-family: Lora, serif;
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.4em;
}

.renters-carousel-card-socials-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-grow: 1;
    align-items: flex-end;
    margin-top: 20px
}

.renters-carousel-card-socials-container img{
    width: 23px;
}

.renters-carousel-mobile-button{
    flex-direction: row;
    gap: 8px;
    align-items: center;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    margin: 0 auto 20px auto;
    padding: 15px;
    display: none;
}

.renters-carousel-mobile-button:hover{
    cursor: pointer;
}

.renters-carousel-mobile-button-text{
    font-family: Poppins, sans-serif;
    font-size: 1em;
    font-weight: 600;
}

.hide-mobile-button{
    display: none !important;
}

@media screen and (max-width: 1100px){
    .renters-carousel-card{
        max-width: 450px;
    }

    .renters-carousel-title{
        font-size: 3em;
    }
}

@media screen and (max-width: 768px){
    .renters-carousel-card{
        max-width: 600px;
    }

    .renters-carousel-title{
        font-size: 1.875em;
    }

    .renters-carousel-description{
        font-size: 1em;
    }
}

@media screen and (max-width: 480px){

    .renters-carousel{
        flex-direction: column;
        gap: 20px;
        padding: 0 17px 20px 17px;
    }

    .renters-carousel-mobile-button{
        display: inline-flex;
    }

    .renters-carousel .renters-carousel-card:nth-child(3n+2) {
        background-color: var(--lightBlue);
    }

    .renters-carousel .renters-carousel-card:nth-child(3n+0) {
        background-color: var(--yellow);
    }

    .renters-carousel-container{
        background: linear-gradient(180deg, #F7F8F8 0%, #FFFFFF 38.54%, #FFFFFF 63.54%, #F5F6F6 100%);
    }
}